import BaseStore from "../common/base.store";

// Set state for this specific vuex store
const useState = {
  list: {
    routeName: "Amazon",
    role: "Issue",
    documentTitle: "g3tools - Amazon Info",
    listName: "Amazon - Inbound Shipments",
    keyField: "x",
    imageField: "imageUrlSmall",
    showOtherActions: true,
    selectMode: "multi",
    showAdd: true,
    allowInsert: false,
    showDelete: true,
    selectRowShowsDetail: false,
    // Fields to appear in table list
    fields: [
      {
        key: "index",
        sortable: false,
        label: "",
        class: "col-right",
      },
      {
        key: "selected",
        sortable: false,
        class: "col-center",
      },
      {
        // imageUrl60
        key: "image",
        label: "Image",
        sortable: false,
        sortBy: "asin",
        class: "col-action",
      },
      {
        key: "id",
        label: "Id",
        sortable: true,
        class: "col-70 col-center",
      },
      {
        key: "createdOn",
        label: "Created",
        class: ["col-center", "col-130"],
        sortable: true,
        formatter: (value, key, item) => {
          return (
            item.createdOn && new Date(item.createdOn).toLocaleDateString()
          );
        },
      },
      {
        key: "ts",
        label: "Modified",
        class: ["col-center"],
        sortable: true,
        formatter: (value, key, item) => {
          return item.ts && new Date(item.ts).toLocaleDateString();
        },
      },
    ],
  },
  entityDescription: "Amazon Inbound Shipments",
  subNavTitle: "Amazon",
  filters: [
    {
      type: "action",
      field: "status",
      label: "Status",
      value: null,
      defaultValue: "Open",
      defaultOperator: "=",
      operator: "=",
      includeInTable: true,
      options: [
        { text: "Open", value: "Open" },
        { text: "Closed", value: "Closed" },
        { text: "Hold", value: "On Hold" },
        { text: "All", value: "All" },
      ],
      allowBulkChange: false,
      fetchOptions: false,
    },
  ],
};

// Instantiate base store, passing in root endpoint and state
const base = new BaseStore("amazon", useState);
let baseState = { ...base.state };

// State object instantiated so we can easily reset using initialState
const initialState = () => {
  return {
    // State from base store with overridden values
    ...baseState,
  };
};
const state = initialState();

// Getter functions
const getters = {
  // Standard getters from base store added here
  ...base.getters,
};

// Actions
const actions = {
  async fetchAll() {
    // Set filters based on route
    //await base.actions.fetchAll({ state, commit, dispatch });
    return null;
  },

  dynamicFilters({ commit }) {
    // Default condition: Receive pending
    // Status filter
    // commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
    //   key: "status",
    //   value: false,
    // });
    // commit("FILTER_DEFAULT_VALUE_SET", {
    //   key: "status",
    //   value: "Pending",
    // });
    // // PurchasedOn filter
    // commit("FILTER_DEFAULT_VALUE_SET", {
    //   key: "purchasedOn",
    //   value: "",
    // });
    commit("LIST_SET", { key: "sortBy", value: "ts" });
    commit("LIST_SET", { key: "sortOrder", value: "DESC" });
    // Set filters based on route name
    //switch (state?.list?.routeName) {
    //   case "PurchasedItemReceiveXXd":
    //     // Receive > 21d
    //     commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
    //       key: "status",
    //       value: true,
    //     });
    //     commit("FILTER_DEFAULT_VALUE_SET", {
    //       key: "purchasedOn",
    //       value: getPastDateInUTC(),
    //     });
    //     commit("LIST_SET", { key: "sortBy", value: "purchasedOn" });
    //     commit("LIST_SET", { key: "sortOrder", value: "ASC" });
    //     break;
    //   case "PurchasedItemReceiveAll":
    //     // Receive all
    //     commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
    //       key: "status",
    //       value: true,
    //     });
    //     commit("FILTER_VALUE_SET", {
    //       key: "status",
    //       value: "",
    //     });
    //     commit("FILTER_DEFAULT_VALUE_SET", {
    //       key: "status",
    //       value: "All",
    //     });
    //     break;
    //}
  },
  // Standard actions from base classes
  ...base.actions,
  // Override clearFilterOnly
  async clearFiltersOnly({ state, commit, dispatch }) {
    // Set filters based on route
    await dispatch("dynamicFilters");
    await base.actions.clearFiltersOnly({ state, commit, dispatch });
  },
};

// Mutations
const mutations = {
  // Standard mutations from base store class
  ...base.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
