<template>
  <div :style="cssVars" >
    <div class="badge-container">
      <span >{{ useClientMnemonic }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Get clientId, clientMnemonic, and badgeColor from Session.user object
// instead of passed into props. But, if scope is tenant and no client
// has been selected, then rely on passed in clientid.
export default {
  props: {
    clientId: {
      type: String,
      required: true,
      default: "",
    },
    colorMethod: {
      type: String,
      default: "palette",
    },
  },
  computed: {
    ...mapGetters("Session", [
      "user",
      "tenantClients"
    ]),
    useClientId() {
      if (this.user?.scope === 'tenant') {
        return this.user?.tenantSelectedClient?.clientId || this.clientId || this.user?.clientId;
      }
      return this.user?.clientId || this.user?.tenantSelectedClient?.clientId;
    },
    useClientMnemonic() {
      if (this.user?.scope === 'tenant') {
        if (this.user?.tenantSelectedClient.clientMnemonic) {
          return this.user?.tenantSelectedClient.clientMnemonic;
        } else {
          // Tenant has not selected a client; try and use passed in clientId
          if (this.clientId) {
            // Find in Session.tenantClients
            const matchedClient = this.tenantClients.find(client => client.clientId === this.clientId);
            if (matchedClient) return matchedClient.clientMnemonic;
          }
        }
      } else {
        return this.user?.client.clientMnemonic;
      }
      return null;
    },
    useBadgeColor() {
      if (this.user?.scope === 'tenant') {
        if (this.user?.tenantSelectedClient.useBadgeColor) {
          return this.user?.tenantSelectedClient.useBadgeColor;
        } else {
          // Tenant has not selected a client; try and use passed in clientId
          if (this.clientId) {
            // Find in Session.tenantClients
            const matchedClient = this.tenantClients.find(client => client.clientId === this.clientId);
            if (matchedClient) {
              return matchedClient.badgeColor;
            }
          }
        }
      } else {
        return this.user?.client.badgeColor;
      }
      return null;
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--badge-color": this.useBadgeColor,
      };
    },
  }
};
</script>

<style scoped>

.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--badge-color) !important;
  color: #efefef;
  height: 30px;
  width: 30px;
  font-size: .8rem;
  border-radius: 50%;
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */

}

</style>