import store from "../store";
import Vue from "vue";
import VueRouter from "vue-router";
import config from "../../config.js";

// Import route modules
//import purchasedItemRoutes from "./purchasedItem.router.js";
//import issueRoutes from "./issue.router.js";
import amazonRoutes from "./amazon.router.js";

// Import other route components
import Login from "../views/auth/Login.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardSlcPrep from "../views/dashboard/DashboardSlcPrep.vue";
import Admin from "../views/auth/Admin.vue";
import Profile from "../views/auth/Profile.vue";
import About from "../views/auth/About.vue";
import FileUpload from "../views/file-upload/FileUpload.vue";
import NotFound from "../views/navigation/PageNotFound.vue";

Vue.use(VueRouter);

// Purchased Item routes
// ***************************************************************
import PurchasedItemHome from "../views/purchased-items/PurchasedItemHome.vue";
//import BaseG3List from "../components/BaseG3List.vue";
import PurchasedItem from "../views/purchased-items/PurchasedItem.vue";
// Names used in routes.
const receivePending = "PurchasedItemReceivePending";
const receiveAll = "PurchasedItemReceiveAll";
const receiveXXd = "PurchasedItemReceiveXXd";
const deletetedPurchasedItems = "DeletedPurchasedItems";
const useNamespaceP = "PurchasedItem";
const showUploadButton = true;
const calcfromDate = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - config.pendingDays);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};
const purchasedItemRoutes = [
  {
    path: "/purch-item/receive",
    //    name: "ReceiveHome",
    component: PurchasedItemHome,
    meta: { requiresAuth: true },
    children: [
      {
        // Receive - Pending
        path: "", // Default child route
        name: receivePending,
        component: BaseG3List,
        props: {
          namespace: useNamespaceP,
          showUploadButton,
        },
        meta: {
          breadcrumb: [
            {
              text: "Purchased Items",
              to: { name: receivePending },
            },
            {
              text: "Pending Items",
            },
          ],
        },
      },
      {
        // Receive - All
        path: "purch-receive--all",
        name: receiveAll,
        component: BaseG3List,
        props: { namespace: useNamespaceP, showUploadButton },
        meta: {
          breadcrumb: [
            {
              text: "Purchased Items",
              to: { name: receiveAll },
            },
            {
              text: "All Purchased Items",
            },
          ],
        },
      },
      {
        // Received > x days
        path: "purch-receive--XXd",
        name: receiveXXd,
        component: BaseG3List,
        props: { namespace: useNamespaceP, showUploadButton },
        meta: {
          breadcrumb: [
            {
              text: "Purchased Items",
              to: { name: receiveXXd },
            },
            {
              text: `Pending Items > ${
                config.pendingDays
              } Days (before ${calcfromDate()})`,
            },
          ],
        },
      },
      {
        // Deleted issues
        path: "purch-receive--deleted",
        name: deletetedPurchasedItems,
        component: BaseG3List,
        props: { namespace: useNamespaceP },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: deletetedPurchasedItems } },
            { text: "Purchased Items - Deleted" },
          ],
        },
      },
      {
        path: ":id/detail",
        name: "PurchasedItem",
        component: PurchasedItem,
        props: true,
        meta: {
          breadcrumb: [
            {
              text: "Purchased Item",
              to: { name: "PurchasedItem" },
            },
            { text: "Purchased Item" },
          ],
        },
      },
    ],
  },
];
//                End of PurchasedItem routes
// **************************************************

// Issue route imports
import IssueHome from "../views/issue/IssueHome.vue";
import BaseG3List from "../components/BaseG3List.vue";
import IssueItem from "../views/issue/IssueItem.vue";
// Issue route constants
const issueOpen = "OpenIssues";
const myAssigned = "IssuesAssignedToMe";
const allIssues = "AllIssues";
const processorAssignedIssues = "IssuesAssignedToProcessor";
const clientAssignedIssues = "IssuesAssignedToClient";
const closedIssues = "ClosedIssues";
const deletedIssues = "DeletedIssues";
const useNamespace = "Issue2";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/DashboardSlcPrep",
    name: "DashboardSlcPrep",
    component: DashboardSlcPrep,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Combine routes
  //...issueRoutes,
  ...purchasedItemRoutes,
  ...amazonRoutes,
  // Issue routes begin
  {
    path: "/issue",
    //    name: "IssueHome",
    component: IssueHome,
    meta: { requiresAuth: true },
    children: [
      {
        // Open issues
        path: "", // Default route
        name: issueOpen,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: issueOpen } },
            { text: "Issues - All Open" },
          ],
        },
      },
      {
        // All issues
        path: "issue/all-issues",
        name: allIssues,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: allIssues } },
            { text: "Issues - All" },
          ],
        },
      },
      {
        // Issues assigned to me
        path: "issue/assigned-to-me",
        name: myAssigned,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: myAssigned } },
            { text: "Open Issues - Assigned to Me" },
          ],
        },
      },
      {
        // Issues assigned to processor
        path: "issue/assigned-to-processor",
        name: processorAssignedIssues,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: processorAssignedIssues } },
            { text: "Open Issues - Assigned to Processor" },
          ],
        },
      },
      {
        // Issues assigned to client
        path: "issue/assigned-to-client",
        name: clientAssignedIssues,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: clientAssignedIssues } },
            { text: "Open Issues - Assigned to Client" },
          ],
        },
      },
      {
        // Closed issues
        path: "issue/closed",
        name: closedIssues,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: closedIssues } },
            { text: "Issues - Closed" },
          ],
        },
      },
      {
        // Deleted issues
        path: "issue/deleted",
        name: deletedIssues,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: deletedIssues } },
            { text: "Issues - Deleted" },
          ],
        },
      },
      {
        path: ":id/detail",
        name: "IssueItem",
        component: IssueItem,
        props: true,
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: "IssueItem" } },
            { text: "Issue Item" },
          ],
        },
      },
    ],
  },
  //  End of issue routes
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { requiresAuth: true },
  },
  {
    path: "/file-upload",
    name: "file-upload",
    component: FileUpload,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
});

// If not authenticated, send user to login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Session/isAuthenticated"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
// If attempting an admin link, must be admin
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters["Session/isAdmin"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});


export default router;
