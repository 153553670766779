<!-- IssueItem.vue -->
<template>
  <b-container fluid class="item-wrapper">
    <div>
      <b-overlay
        variant="white"
        :show="isLoading && showLoading"
        :opacity="0.0"
        spinner-variant="info"
      >
        <BaseAlert :mode="mode" width="35em" top="20px" :timeout="7" />
        <b-form
          @submit.prevent="formSave"
          @keydown.enter.exact="formSave"
          @keydown.esc.exact="formCancel"
          enctype="multipart/form-data"
        >
          <!-- Main form card -->
          <b-card
            no-body
            header-tag="header"
            footer-tag="footer"
            class="no-border"
          >
            <!-- Header -->
            <template #header>
              <div class="item-modal-header-content">
                <!-- Record id -->
                <div>
                  <span class="mr-1 text-muted small">
                    {{ mode === "new" ? "New Issue" : `Id:` }}</span
                  >
                  <span class="small">{{ form?.id }}</span>
                  <span class="deleted-flag ml-2" v-if="currentItem.deleted">Deleted</span>
                </div>
                <!-- Description  -->
                <h5 class="item-header-description issue-header-container">
                  <span>{{ headerDescription1() }} </span>
                  <BaseClientBadge  :clientId="form.clientId || ''" class="ml-2 mr-2" style="font-weight: 200"/>
                  <span> {{ headerDescription2() }} </span>
                </h5>
                <button
                  @click="formCancel"
                  class="item-close-btn"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
            </template>
            <!-- Card body slot -->
            <b-card no-body class="bg-light">
              <div class="control-number-container">
                <span class="control-number-label">Control:</span>
                <span class="control-number">{{form.controlNumber}}</span>
              </div>
              <b-tabs
                card
                nav-class="text-capitalize"
                small
                active-nav-item-class="font-weight-bold text-uppercase text-info"
                content-class="mt-0 p-2"
              >
                <!-- MAIN TAB -->
                <b-tab lazy title="Main" class="bg-light pt-2 pb-0">
                  <template>
                    <b-row class="p-0">
                      <!-- Entire tab page, full width -->
                      <b-col cols="8" class="pr-0">
                        <!-- Left main column -->
                        <b-row>
                          <!-- Issue qty and status/priority row -->
                          <b-col>
                            <div class="p-0">
                              <!-- Issue Qty -->
                              <BaseFormSpinner
                                id="issueQty2"
                                label="Issue qty:"
                                ref="issueQtySpinnerRef"
                                :labelCols="mode === 'new' ? 3 : 3"
                                marginLeftValue="6px"
                                :inline="true"
                                :autofocus="true"
                                labelWidth="75px"
                                :min="0"
                                :max="99"
                                size="sm"
                                :readonly="!isAuthorized('Issue', 'update') || deleted"
                                v-model="form.issueQty"
                                :state="getFieldState('issueQty')"
                                @input="$v.form.issueQty.$touch()"
                                :error="$v.form.issueQty.$error"
                                errorMsg="Issue qty must be > 0"
                                valueWidth="120px"
                                compact
                                class="mb-1"
                              />
                            </div>
                          </b-col>
                          <b-col>
                            <!-- Status & priority -->
                            <div>
                              <!-- Status -->
                              <BaseFormRadio
                                id="statusId"
                                ref="status-radio"
                                name="statusId"
                                label="Status:"
                                :labelCols="mode === 'new' ? 3 : 3"
                                :namespace="namespace"
                                v-if="isAuthorized('Issue', 'update')"
                                fieldName="statusName"
                                optionsField="itemOptions"
                                text-field="statusName"
                                value-field="statusId"
                                v-model="form.statusId"
                                :state="getFieldState('statusId')"
                                :readonly="deleted"
                                @input="$v.form.statusId.$touch()"
                                @g3Change="optionFieldChange"
                                :error="$v.form.statusId.$error"
                                errorMsg="Status is required"
                                @blur="$v.form.statusId.$touch()"
                                buttons
                                buttonVariant="outline-info"
                                compact
                                size="sm"
                              />
                              <!-- Status - read only -->
                              <BaseFormInput
                                id="status"
                                name="statusId"
                                label="Status:"
                                :labelCols="mode === 'new' ? 3 : 3"
                                v-if="!isAuthorized('Issue', 'update')"
                                v-model="form.statusId"
                                :state="getFieldState('statusId')"
                                :readonly="!isAuthorized('Issue', 'update')"
                                size="sm"
                              />
                              <!-- Priority - Select -->
                              <BaseFormSelect
                                id="priorityId"
                                name="priorityId"
                                label="Priority:"
                                :labelCols="mode === 'new' ? 3 : 3"
                                :namespace="namespace"
                                fieldName="priorityName"
                                optionsField="itemOptions"
                                text-field="priorityName"
                                value-field="priorityId"
                                v-model="form.priorityId"
                                :state="getFieldState('priorityId')"
                                @input="$v.form.priorityId.$touch()"
                                @g3Change="optionFieldChange"
                                :disabled="!isAuthorized('Issue', 'update') || deleted"
                                :error="$v.form.priorityId.$error"
                                errorMsg="Priority is required"
                                @blur="$v.form.priorityId.$touch()"
                                size="sm"
                              />
                              <!-- Priority - Radio -->
                              <BaseFormRadio
                                id="priority"
                                name="priorityId"
                                label=""
                                :labelCols="mode === 'new' ? 0 : 0"
                                :namespace="namespace"
                                v-if="false"
                                visibility="hidden"
                                fieldName="priorityName"
                                optionsField="itemOptions"
                                v-model="form.priorityId"
                                :state="getFieldState('priorityId')"
                                :disabled="deleted"
                                @input="$v.form.priorityId.$touch()"
                                @g3Change="optionFieldChange"
                                :error="$v.form.priorityId.$error"
                                errorMsg="Priority is required"
                                @blur="$v.form.priorityId.$touch()"
                                buttons
                                buttonVariant="outline-info"
                                size="sm"
                              />
                              <!-- Priority - read only -->
                              <BaseFormInput
                                id="priority"
                                name="priorityId"
                                label="Prioirty:"
                                :labelCols="mode === 'new' ? 3 : 3"
                                v-if="!isAuthorized('Issue', 'update')"
                                v-model="form.priorityId"
                                :state="getFieldState('priorityId')"
                                :readonly="!isAuthorized('Issue', 'update')"
                                size="sm"
                              />
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- Bottom left tab -->
                          <b-col>
                            <div>
                              <!-- Type (Reason) -->
                              <BaseFormSelect
                                id="typeId"
                                name="typeId"
                                label="Type:"
                                :labelCols="mode === 'new' ? 2 : 2"
                                :namespace="namespace"
                                fieldName="typeName"
                                text-field="typeName"
                                value-field="typeId"
                                optionsField="itemOptions"
                                v-model="form.typeId"
                                :state="getFieldState('typeId')"
                                @input="$v.form.typeId.$touch()"
                                @g3Change="optionFieldChange"
                                :readonly="!isAuthorized('Issue', 'update') || deleted"
                                :error="$v.form.typeId.$error"
                                errorMsg="Type is required"
                                @blur="$v.form.typeId.$touch()"
                                compact
                                size="sm"
                              />
                              <!-- Reason -->
                              <BaseFormTextarea
                                id="issueReason"
                                autocomplete="off"
                                label="Reason:"
                                :labelCols="mode === 'new' ? 2 : 2"
                                v-model="form.issueReason"
                                :state="getFieldState('issueReason')"
                                @input="$v.form.issueReason.$touch()"
                                @keydown.enter.stop
                                :disabled="!isAuthorized('Issue', 'update') || deleted"
                                :error="$v.form.issueReason.$error"
                                errorMsg="Issue reason is required"
                                size="sm"
                                :rows="2"
                                compact
                              />
                              <!-- Assignee -->
                              <BaseFormSelect
                                id="assigneeId"
                                name="assigneeId"
                                label="Assignee:"
                                :labelCols="mode === 'new' ? 2 : 2"
                                :namespace="namespace"
                                text-field="assigneeFamiliarName"
                                value-field="assigneeId"
                                fieldName="assigneeFamiliarName"
                                optionsField="itemOptions"
                                v-model="form.assigneeId"
                                @input="$v.form.assigneeId.$touch()"
                                @g3Change="optionFieldChange"
                                :readonly="!isAuthorized('Issue', 'update') || deleted"
                                :state="getFieldState('assigneeId')"
                                :error="$v.form.assigneeId.$error"
                                errorMsg="Assignee is required"
                                @blur="$v.form.assigneeId.$touch()"
                                size="sm"
                                compact
                              />
                              <hr />
                              <!-- Action -->
                              <BaseFormSelect
                                id="actionId"
                                name="actionId"
                                label="Action:"
                                :labelCols="mode === 'new' ? 2 : 2"
                                :namespace="namespace"
                                fieldName="actionName"
                                :firstOption="true"
                                text-field="actionName"
                                value-field="actionId"
                                optionsField="itemOptions"
                                v-model="form.actionId"
                                :state="getFieldState('actionId')"
                                @input="$v.form.actionId.$touch()"
                                @g3Change="optionFieldChange"
                                :readonly="!isAuthorized('Issue', 'update') || actionDisabled || deleted"
                                :error="
                                  $v.form.actionId.$error ||
                                  $v.form.actionId.actionInvalidIfQtysExist
                                "
                                errorMsg="Must set one of the action quantities > 0 if action is selected"
                                size="sm"
                                compact
                              />
                              <!-- Action option description -->
                              <div>
                              </div>
                              <div v-if="form.actionName !== 'Other Action'" >
                                <BaseFormTextarea
                                  id="actionDescription"
                                  autocomplete="off"
                                  :readonly=true
                                  :labelCols="mode === 'new' ? 2 : 2"
                                  v-model="form.actionDescription"
                                  :state="getFieldState('actionDescription')"
                                  @keydown.enter.stop
                                  size="sm"
                                  :rows="2"
                                  compact
                                  italic
                                  deemphasize
                                />
                              </div>
                              <!-- Other action description -->
                              <div v-if="form.actionName === 'Other Action'">
                                <BaseFormTextarea
                                  id="otherActionDescription"
                                  autocomplete="off"
                                  :placeholder="form.actionDescription"
                                  :labelCols="mode === 'new' ? 2 : 2"
                                  v-model="form.otherActionDescription"
                                  :state="getFieldState('otherActionDescription')"
                                  @input="$v.form.otherActionDescription.$touch()"
                                  @keydown.enter.stop
                                  :disabled="!isAuthorized('Issue', 'update') || deleted "
                                  :readonly="actionDisabled"
                                  :error="$v.form.otherActionDescription.$error"
                                  errorMsg="Other action description is requried if Action is 'Other Action'"
                                  size="sm"
                                  :rows="2"
                                  compact
                                />
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row style="height: 35px">
                          <!-- More qty's -->
                          <b-col>
                            <!-- Returned/listed qty -->
                            <div class="mb-0 pb-0">
                              <!-- Returned Qty -->
                              <BaseFormSpinner
                                id="returnedQty"
                                label="Returned:"
                                :labelCols="mode === 'new' ? 3 : 3"
                                marginLeftValue="6px"
                                :inline="true"
                                labelWidth="75px"
                                :min="0"
                                :max="99"
                                size="sm"
                                :readonly="!isAuthorized('Issue', 'update') || deleted"
                                v-model="form.returnedQty"
                                :disabled="actionQtyDisabled"
                                :state="getFieldState('returnedQty')"
                                @input="$v.form.returnedQty.$touch()"
                                :error="$v.form.returnedQty.$error"
                                errorMsg="Error on Returned qty"
                                valueWidth="120px"
                                compact
                              />
                            </div>
                          </b-col>
                          <b-col>
                            <!-- Disposed/Other qty -->
                            <div class="mb-0 pb-0">
                              <!-- Disposed Qty -->
                              <BaseFormSpinner
                                id="disposedQty"
                                :inline="true"
                                label="Disposed:"
                                :labelCols="mode === 'new' ? 3 : 3"
                                labelWidth="75px"
                                marginLeftValue="42px"
                                :min="0"
                                :max="99"
                                size="sm"
                                :readonly="!isAuthorized('Issue', 'update') || deleted" 
                                v-model="form.disposedQty"
                                :disabled="actionQtyDisabled"
                                :state="getFieldState('disposedQty')"
                                @input="$v.form.disposedQty.$touch()"
                                :error="$v.form.disposedQty.$error"
                                errorMsg="Error on Disposed qty"
                                valueWidth="120px"
                                compact
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col class="pr-0 mr-1">
                        <div class="mb-0 pb-0">
                          <!-- Right main tab -->
                          <hr v-if="!itemImage()" />
                          <!-- Image -->
                          <b-card no-body v-if="itemImage()">
                            <div
                              class="mx-auto my-auto pt-2"
                              :class="{ 'pt-1': isDefaultImage }"
                              style="height: 142px"
                            >
                              <b-img
                                :src="itemImage()"
                                alt="Item image"
                              ></b-img>
                            </div>
                          </b-card>
                          <!-- Product description -->
                          <div class="pb-0 mt-1 description-block">
                            <span class="small" v-if="productId">
                              <b-link
                                id="amazon-link"
                                target="_blank"
                                :href="`https://www.amazon.com/dp/${productId}/`"
                                rel="noopener noreferrer"
                                tabindex="-1"
                              >
                                {{ `${productId}: ` }}
                              </b-link>
                              <b-tooltip target="amazon-link"
                                >Click to see Amazon listing</b-tooltip
                              >
                            </span>
                            <span class="product-description">
                              <i>{{ form.productDescription }}</i>
                            </span>
                            <div class="purchased-container mt-3" v-if="form.purchasedQty > 0">
                              <span class="small-label-text mr-1">Purchased</span>
                              <span class="purchased-qty-field mr-1">{{ form.purchasedQty }}</span>
                              <span class="small-label-text mr-1">on</span>
                              <span class="small-label-text mr-1">{{formattedPurchasedOn}}</span>
                              <span class="small-label-text mr-1">from</span>
                              <span class="purchased-qty-field mr-1">{{form.supplier}}.</span>
                              <span class="small-label-text mr-1">Received: </span>
                              <span class="purchased-qty-field">{{form.pastReceivedQty}}</span>
                              <span class="small-label-text mr-1">. </span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Listed / Other qty and reason -->
                      <b-col class="pr-0">
                        <div
                          class="d-flex justify-content-between align-content-middle"
                        >
                          <BaseFormSpinner
                            id="listedQty"
                            label="Received:"
                            :labelCols="mode === 'new' ? 3 : 3"
                            marginLeftValue="6px"
                            :inline="true"
                            labelWidth="75px"
                            :min="0"
                            :max="99"
                            size="sm"
                            :readonly="!isAuthorized('Issue', 'update') || deleted"
                            v-model="form.listedQty"
                            :disabled="actionQtyDisabled"
                            :state="getFieldState('listedQty')"
                            @input="$v.form.listedQty.$touch()"
                            :error="$v.form.listedQty.$error"
                            errorMsg="Error on Listed qty"
                            valueWidth="120px"
                            compact
                          />
                          <!-- Other Qty -->
                          <BaseFormSpinner
                            id="otherQty"
                            label="Other qty:"
                            :labelCols="mode === 'new' ? 3 : 3"
                            marginLeftValue="41px"
                            marginLeft="38px"
                            :inline="true"
                            labelWidth="75px"
                            :min="0"
                            :max="99"
                            size="sm"
                            :readonly="!isAuthorized('Issue', 'update') || deleted"
                            v-model="form.otherQty"
                            :disabled="actionQtyDisabled"
                            :state="getFieldState('otherQty')"
                            @input="
                              $v.form.otherQty.$touch() ||
                                $v.form?.otherQtyReasonValidation?.$touch()
                            "
                            :error="$v.form.otherQty.$error"
                            errorMsg="Error on Other qty"
                            valueWidth="120px"
                            compact
                          />
                          <!-- Other qty reason -->
                          <div class="other-reason">
                            <BaseFormInput
                              id="otherQtyReason"
                              name="otherQtyReason"
                              valueHeight="29px"
                              v-model="form.otherQtyReason"
                              placeholder="Other quantity reason"
                              :state="getFieldState('otherQtyReason')"
                              :readonly="!isAuthorized('Issue', 'update') || deleted"
                              :disabled="
                                !form.otherQty || 
                                form.otherQty === 0 ||
                                form.otherQty === null ||
                                !form.actionId
                              "
                              :error="
                                $v.form.$error ||
                                $v.form?.otherQtyReasonValidation
                              "
                              errorMsg="Reason is required if Other qty > 0"
                              @blur="$v.form.otherQtyReason.$touch()"
                              @input="$v.form.otherQtyReason.$touch()"
                              size="sm"
                              textFieldWidth="250"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                </b-tab>
                <!-- COMMENTS tab -->
                <b-tab lazy title="Comment" class="bg-light">
                  <h6
                    class="comments-heading"
                    v-if="isAuthorized('IssueComment', 'read')"
                  >
                    Comments
                  </h6>
                  <div class="comments-scrollable-list ml-4">
                    <IssueComments
                      :namespace="namespace"
                      :comments="comments"
                      :user="user"
                      :disabled="deleted"
                      @g3CommentDirty="handleCommentDirty"
                      @g3CommentDeleted="handleCommentDeleted"
                      @g3CommentBlurred="handleCommentBlurred"
                      v-if="isAuthorized('IssueComment', 'read')"
                    />
                  </div>
                </b-tab>
                <!-- Other Info TAB ************************* -->
                <b-tab lazy class="mt-0 pb-2 pr-2 bg-light" title="Other Info">
                  <template>
                    <!-- First row -->
                    <b-form-row>
                      <!-- Left column -->
                      <b-col cols="6" class="mr-1">
                        <!-- Client Facility -->
                        <BaseFormSelect
                          id="clientFacilityName"
                          name="clientFacilityName"
                          label="Client facility:"
                          :labelCols="mode === 'new' ? 4 : 4"
                          :namespace="namespace"
                          fieldName="clientFacilityName"
                          text-field="clientFacilityName"
                          value-field="clientFacilityId"
                          optionsField="itemOptions"
                          marginLeftValue="21"
                          textFieldWidth="230"
                          v-model="form.clientFacilityId"
                          :disabled="!isAuthorized('Issue', 'update') || deleted"
                          :error="$v.form.clientFacilityId.$error"
                          errorMsg="Client facility is required"
                          :state="getFieldState('clientFacilityId')"
                          @blur="$v.form.clientFacilityId.$touch()"
                          @input="$v.form.clientFacilityId.$touch()"
                          @g3Change="optionFieldChange"
                          compact
                          size="sm"
                        />
                        <!-- Client LOB -->
                        <BaseFormSelect
                          id="clientLobName"
                          name="clientLobName"
                          label="Client LOB:"
                          :labelCols="mode === 'new' ? 4 : 4"
                          :namespace="namespace"
                          fieldName="clientLobName"
                          text-field="clientLobName"
                          value-field="clientLobId"
                          optionsField="itemOptions"
                          marginLeftValue="21"
                          textFieldWidth="230"
                          v-model="form.clientLobId"
                          :disabled="!isAuthorized('Issue', 'update') || deleted"
                          :error="$v.form.clientLobId.$error"
                          errorMsg="Client line of business is required"
                          :state="getFieldState('clientLobId')"
                          @blur="$v.form.clientLobId.$touch()"
                          @input="$v.form.clientLobId.$touch()"
                          @g3Change="optionFieldChange"
                          compact
                          size="sm"
                        />
                      </b-col>
                      <!-- Right column -->
                      <b-col class="text-right">
                        <!-- Processor Facility -->
                        <BaseFormSelect
                          id="processorFacilityName"
                          name="processorFacilityName"
                          label="Processor facility:"
                          :labelCols="mode === 'new' ? 4 : 4"
													labelAlign="right"
                          :namespace="namespace"
                          fieldName="processorFacilityName"
                          value-field="processorFacilityId"
                          text-field="processorFacilityName"
                          optionsField="itemOptions"
                          v-model="form.processorFacilityId"
                          :disabled="!isAuthorized('Issue', 'update') || deleted"
                          :error="$v.form.processorFacilityId.$error"
                          :state="getFieldState('processorFacilityId')"
                          @blur="$v.form.processorFacilityId.$touch()"
                          @input="$v.form.processorFacilityId.$touch()"
                          @g3Change="optionFieldChange"
                          compact
                          size="sm"
                        />
                        <!-- Processor LOB -->
                        <BaseFormSelect
                          id="processorLobName"
                          name="processorLobName"
                          label="Processor LOB:"
                          :labelCols="mode === 'new' ? 4 : 4"
													labelAlign="right"
                          :namespace="namespace"
                          fieldName="processorLobName"
                          text-field="processorLobName"
                          value-field="processorLobId"
                          optionsField="itemOptions"
                          v-model="form.processorLobId"
                          :disabled="!isAuthorized('Issue', 'update') || deleted"
                          :error="$v.form.processorLobId.$error"
                          errorMsg="Processor line of business is required"
                          :state="getFieldState('processorLobId')"
                          @blur="$v.form.processorLobId.$touch()"
                          @input="$v.form.processorLobId.$touch()"
                          @g3Change="optionFieldChange"
                          compact
                          size="sm"
                        />
                      </b-col>
                    </b-form-row>
                    <hr />
                    <!-- Second row - Single column -->
                    <b-form-row>
                      <b-col>
                        <div class="sub-heading mb-2">Linked Purchase Item</div>
                        <b-row>
                          <!-- purchasedItemRecordId -->
                          <div class="d-flex justify-content-between align-items-start">
                            <label class="left-label mt-1">Record id:</label>
                            <div class="d-flex flex-column">
                              <b-form-input 
                                id="purchRecordId"
                                ref="purchRecordIdRef"
                                type="text"
                                size="sm"
                                class="left-field"
                                v-model="form.purchasedItemRecordId"
                                :state="getFieldState('purchasedItemRecordId')"
                                :readonly="!isAuthorized('Issue', 'update') || deleted"
                                @blur="$v.form.purchasedItemRecordId.$touch()"
                                :error="$v.form.purchasedItemRecordId?.$error"
                                :disabled="!!currentItem.purchasedItemRecordId"
                                />
                              <b-form-invalid-feedback v-if="$v.form.purchasedItemRecordId?.$error">
                                {{ purchasedItemRecordErrorMessage }}
                              </b-form-invalid-feedback>                     
                            </div>
                            <b-button
                              variant="secondary"
                              size="sm"
                              v-if="currentItem?.purchasedItemRecordId"
                              @click="unlinkPurchasedItem"
                              >Unlink Purchased Item</b-button
                            >
                            <b-button
                              variant="secondary"
                              size="sm"
                              v-if="!currentItem?.purchasedItemRecordId"
                              @click="linkPurchasedItem"
                              :disabled="!form.purchasedItemRecordId"
                              >Link Purchased Item</b-button
                            >
                          </div>
                        </b-row>
                        <div class="mt-4">
                          <IssuePurchasedItemDetail
                          ref="issuePurchasedItemDetailRef"
                            :issue="currentItem"
                          />
                        </div>
                      </b-col>
                    </b-form-row>
                  </template>
                </b-tab>
                <!-- Attach files -->
                <b-tab class="mt-0 pb-2 bg-light">
                  <template slot="title">
                    Attachments
                    <span v-if="currentItem?.hasAttachments">
                      <i :class="'fas fa-paperclip paper-clip'"></i>
                    </span>
                  </template>
                  <div class="container">
                    <b-row>
                      <b-col>
                        <BaseDropzone
                          :recId="form.id"
                          :tenantId="form.tenantId || ''"
                          :clientId="form.clientId || ''"
                          :clientName="form.clientName || ''"
                          :processorName="form.processorName || ''"
                          :namespace="namespace"
                          ref="dropzone_ref"
                          @attachmentsChanged="setAttachmentsChanged"
                          :disabled="deleted"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
                <!-- DETAIL/HISTORY -->
                <b-tab lazy title="Detail/History	" class="bg-light">
                  <template>
                    <div class="m-3">
                      <b-form-row>
                        <h4 class="pb-0 pl-1 history-heading">Record Detail</h4>
                      </b-form-row>
                      <b-form-row>
                        <b-col cols="7">
                          <div>
                            <!-- Top-left controls -->
                            <BaseFormInput
                              v-for="item in topLeft"
                              :key="item?.id"
                              :id="item?.id"
                              :label="item.label"
                              :labelCols="4"
                              plaintext
                              labelClass="text-muted"
                              size="sm"
                              readonly
                              compact
                              :value="item.value"
                            />
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <!-- Top-right controls -->
                            <BaseFormInput
                              v-for="item in topRight"
                              :key="item?.id"
                              :id="item?.id"
                              :label="item.label"
                              :labelCols="5"
                              labelAlign="right"
                              plaintext
                              labelClass="text-muted"
                              size="sm"
                              readonly
                              compact
                              :value="item.value"
                            />
                          </div>
                        </b-col>
                      </b-form-row>
                      <hr />
                      <!-- History -->
                      <b-form-row>
                        <h4 class="pb-0 pl-1 history-heading">
                          Record History
                        </h4>
                      </b-form-row>
                      <div v-if="hasNoHistory" class="no-history">
                        No history to display
                      </div>
                      <div v-if="!hasNoHistory">
                        <div class="history-scrollable-list">
                          <b-list-group
                            v-for="(hx, index) in historyLog"
                            :key="index"
                            class="history-item p-0"
                          >
                            <b-list-group-item class="list-item px-3 py-1">
                              <BaseLogItem
                                :item="hx"
                                userWidth="87px"
                                class="log-item"
                              />
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
            </b-card>
            <!-- Card footer slot -->
            <template #footer>
              <div class="d-flex justify-content-between">
                <div>
                  <b-button
                    class="mr-3"
                    variant="success"
                    @click="onComplete"
                    :disabled="mode !== 'update' || form.statusId === 2 || deleted"
                  >
                    Complete Issue
                  </b-button>
                  <b-button
                    class="mr-3"
                    variant="secondary"
                    @click="onUndelete"
                    :disabled="!deleted"
                    v-if="currentItem.deleted"
                  >
                    Undelete Issue
                  </b-button>
                </div>
                <div class="d-flex">
                  <b-button
                    class="mr-3"
                    variant="secondary"
                    @click="formCancel"
                    >{{ isFormDirty ? "Cancel" : "Close" }}</b-button
                  >
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="
                      !isFormDirty && !attachmentsChanged
                    "
                    v-if="isAuthorized('Issue', 'update')"
                    >Save</b-button
                  >
                </div>
              </div>
            </template>
          </b-card>
        </b-form>
      </b-overlay>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minValue, integer } from "vuelidate/lib/validators";
import {
  handleBeforeDestroy,
  handleCreated,
  handleFormCancel,
  handleFormSave,
  handleItemImage,
  handleSyncOptionName,
  handleValidateState,
  handleConfirmChanges,
} from "../../utils/component-utils";
import IssuePurchasedItemDetail from "./IssuePurchasedItemDetail.vue";
import IssueComments from "./IssueComments.vue";
import config from "../../../config.js";

export default {
  components: {
    IssuePurchasedItemDetail,
    IssueComments,
  },
  name: "IssueItem",
  props: {
    namespace: { type: String, default: "Issue2" },
    item: { type: Object, required: true },
    calledFromModal: { type: Boolean, default: false },
    parentNamespace: { type: String, required: true },
    parentTenantId: { type: String, default: "" },
    parentClientId: { type: String, default: "" },
    parentId: { type: Number, default: null },
  },
  data() {
    return {
      mode: "new",
      // isModalVisible: false,
      form: {
        id: "",
        actionDescription: "",
        actionId: null,
        actionName: "",
        asin: "",
        assigneeId: "",
        assigneeFamiliarName: "",
        assigneeFullName: "",
        assigneeUserId: "",
        calledFromPurchasedItem: false,
        clientFacilityId: "",
        clientFacilityName: "",
        clientLobId: "",
        clientLobName: "",
        clientId: "",
        clientName: "",
        controlNumber: "",
        createdBy: "",
        createdOn: "",
        disposedQty: 0,
        imageUrl: "",
        issueReason: "",
        issueQty: 0,
        lastUpdatedBy: "",
        listedQty: 0,
        originator: "",
        otherActionDescription: "",
        otherQty: 0,
        otherQtyReason: 0,
        pastReceivedQty: 0,
        priorityId: 2,
        priorityName: "",
        processorFacilityId: "",
        processorFacilityName: "",
        processorLobId: "",
        processorLobName: "",
        processorName: "",
        productDescription: "",
        productId: "",
        purchasedItemRecordId: null,
        purchasedQty: 0,
        purchasedOn: "",
        supplier: "",
        returnedQty: 0,
        statusId: "Open",
        statusName: "",
        tenantId: "",
        totalIssueQty: 0,
        ts: "",
        typeId: "",
        typeName: "",
        unread: false,
      },
      attachmentsChanged: false,
      confirmingChanges: false,
      // childDirty: false,
      isDefaultImage: false,
      productInfo: {},
      purchasedItemRecordErrorMessage: "",
      hasNoHistory: false,
    };
  },
  validations: {
    // *********************************************
    //          Custom validators
    // Return true = validated
    form: {
      actionId: {
        actionInvalidIfQtysExist(value) {
          const { returnedQty, disposedQty, listedQty, otherQty } = this.form;
          // Fail validation only if actionId is selected and all quantities are zero
          if (value && !this.actionQtyDisabled) {
            const isValid = (
              returnedQty > 0 ||
              disposedQty > 0 ||
              listedQty > 0 ||
              otherQty > 0
            );
            return isValid;
          }
          return true;
        },
      },
      assigneeId: { required },
      asin: {},
      attachments: [],
      clientFacilityId: { required },
      clientLobId: { required },
      disposedQty: {},
      issueQty: { minValue: minValue(1) },
      issueReason: {},
      listedQty: {},
      originator: {},
      otherActionDescription: {
        otherActionDescriptionValidation(value) {
          if (this.form.actionName === 'Other Action') {
            return value != null && value.trim() !== "";
          }
          return true;
        },
      },
      otherQty: {},
      otherQtyReason: {
        otherQtyReasonValidation(value) {
          if (this.form.otherQty > 0) {
            return value != null && value.trim() !== "";
          }
          return true;
        },
      },
      priorityId: { required },
      processorFacilityId: { required },
      processorLobId: { required },
      purchasedItemRecordId: {
        integer,
        isValid: {
          $validator() {
            return !this.purchasedItemRecordErrorMessage;
          },
          $message() {
            return this.purchasedItemRecordErrorMessage || "Invalid purchased item record ID.";
          },
        },
      },
      returnedQty: {},
      statusId: { required },
      typeId: { required },
      unread: {},
    },
  },
  async created() {
    try {
      // this.childDirty = false;
      await handleCreated(this, document);
      this.$nextTick(() => {
        this.$v.$touch();
        this.$v.$reset();
        // this.childDirty = false;
        this.noHistory();
      });
      this.form.calledFromPurchasedItem = this.calledFromModal;
    } catch (error) {
      console.error("Error during component creation", error);
    }
  },
  mounted() {
    if (this.form.actionName !== "Other Action"){
      this.optionFieldChange({field: 'actionId', value: this.form.actionId})
    }
  },
  watch: {
    form: {
      handler(newValue) {
        if (newValue.otherQty === 0) {
          this.form.otherQtyReason = "";
        }
      },
      deep: true,
    },
    currentItem: {
      handler() {
      this.noHistory();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters("Session", [
      "isLoading",
      "showLoading",
      "isAuthorized",
      "user",
      "authToken",
      "tenantClients",
    ]),

    actionDisabled() {
      return !config.app.allowProcessorIssueActionChange && this.user.scope === "tenant";
    },

    actionQtyDisabled() {
      if (this.user.scope === "client"){
        return true;
      } else if (this.user.scope === "tenant" && !this.form.actionId) {
        return true;
      } else return false;
    },

    clientId() {
      return (
        this.$props?.parentClientId ||
        this.user?.tenantSelectedClient?.clientId ||
        this.user.clientId ||
        ""
      );
    },
    comments() {
      return this.$store.getters[`${this.namespace}/comments`];
    },
    currentItem() {
      // Store to component data binding
      return this.$store.getters[`${this.namespace}/currentItem`];
    },
    deleted() {
      if (this.currentItem.deleted === 1) return true;
      else if (this.currentItem.deleted === 0) return false;
      else return this.currentItem.deleted;
    },
    formattedPurchasedOn() {
      return new Date(this.form.purchasedOn).toLocaleString(undefined, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric'
      });
    },
    isFormDirty() {
      //return this.childDirty || this.$v.$anyDirty;
      return this.$v.$anyDirty;
    },
    historyLog() {
      return this.currentItem?.logs;
    },
    productId() {
      return this.form.asin ? this.form.asin : this.form.productId
    },
    purchasedItemDetails() {
      return this.form.purchasedItemRecordId ? this.currentItem : {};
    },
    sanitizedPurchasedItemRecordId: {
      get() {
        return this.form.purchasedItemRecordId;
      },
      set(value) {
        // Remove any non-numeric characters
        this.form.purchasedItemRecordId = value.replace(/\D/g, "");
      }
    },
    tenantId() {
      return this.$props?.parentTenantId || this.user?.tenantId || "";
    },
    topLeft() {
      // Detail/history
      return [
        { id: "id", label: "Record id:", value: this.form?.id },
        {
          id: "processorName",
          label: "Processor name:",
          value: this.form.processorName,
        },
        {
          id: "clientName",
          label: "Client name:",
          value: this.form.clientName,
        },
        { id: "tenantId", label: "Tenant id:", value: this.form.tenantId },
        { id: "clientId", label: "Client id:", value: this.form.clientId },
        {
          id: "unread",
          label: "Unread by assignee:",
          value: this.convertNumberToBoolean(this.form.unread),
        },
      ];
    },
    topRight() {
      // Detail/history
      return [
        {
          id: "assigneeFullName",
          label: "Assignee name:",
          value: this.form.assigneeFullName,
        },
        {
          id: "assigneeUserId",
          label: "Assignee user id:",
          value: this.form.assigneeUserId,
        },
        {
          id: "createdOn",
          label: "Created on:",
          value: new Date(this.form.createdOn).toLocaleString(),
        },
        { id: "createdBy", label: "Created by:", value: this.form.createdBy },
        {
          id: "lastEditedOn",
          label: "Last updated on:",
          value: new Date(this.form.ts).toLocaleString(),
        },
        {
          id: "lastUpdatedBy",
          label: "Last updated by:",
          value: this.form.lastUpdatedBy,
        },
      ];
    },
  },
  methods: {
    noHistory() {
      //      return this.historyLog.length > 0;
      return this.$nextTick(() => {
        if (!this.currentItem) {
          this.hasNoHistory = true;
        } else if (this.currentItem?.logs?.length > 0) {
          this.hasNoHistory = false;
        } else {
          this.hasNoHistory = true;
        }
      });
    },
    // markFieldDirty() {
    //   this.childDirty = true;
    // },
    // *********************************************
    //          Entity-specific Methods
    // *********************************************
    beforeDestroy() {
      handleBeforeDestroy(this, document);
    },
    getFieldState(data) {
      return handleValidateState(this, data);
    },
    convertNumberToBoolean(value) {
      return value === 1 ? "true" : "false";
    },
    handleCommentBlurred(data) {
      this.$store.dispatch(`${this.namespace}/updateComment`, data.comment);
    },
    handleCommentDeleted(item) {
      this.$store.commit(`${this.namespace}/COMMENT_ITEM_DELETE`, item.id);
      this.$v.form.$touch();
    },
    handleCommentDirty() {
      // Mark form as dirty
      this.$v.form.$touch();
    },
    headerDescription1() {
      let description = 'Issue';
      if (this.form.asin || this.form.productId) {
        const onText = ` on ${this.form.asin || this.form.productId}`;
        description += onText;
      }
      return description;
    },
    headerDescription2() {
      let description = '';
      if (this.form.clientName) {
        const forText = `for ${this.form.clientName.length > 50 ? `${this.form.clientName.substring(0, 50)}...` : this.form.clientName}`;
        description += forText;
      }
      return description;
    },
    itemImage() {
      return handleItemImage(this);
    },
    async onComplete() {
      // Ensure Closed status is id 2 //TODO: Need to ensure this and possibly allow for different status=closed
      this.form.statusId = 2;
      this.form.statusName = "Closed";
      this.formSave();
    },
    async onUndelete() {
      this.$store.dispatch(`${this.namespace}/baseUndelete`);
      this.$v.form.$touch();
    },

    async unlinkPurchasedItem() {
      const message = `Are you sure you want to unlink this issue from Purchased Item record id: ${this.form.purchasedItemRecordId}`;
      const answer = await handleConfirmChanges(this, message);
      if (!answer) return;
      this.$v.form.purchasedItemRecordId.$touch();
      this.clearProductInfo();
      this.$v.form.purchasedItemRecordId.$touch();
    },
    clearProductInfo() {
      this.$v.form.purchasedItemRecordId.$reset();
      this.form.purchasedItemRecordId = "";
      // Remove all product info?
      const clearData = {
        asin: "",
        productDescription: "",
        fnsku: "",
        trackingNumber: "",
        orderNumber: "",
        productId: "",
        sku: "",
        upc: "",
        purchasedQty: "",
        purchasedOn: "",
        supplier: "",
        extraQty: "",
        canceledQty: "",
        replacementQty: "",
        imageUrl: "",
        purchasedItemRecordId: null,
      }
      // Clear form
      for (const key in clearData) {
        if (Object.prototype.hasOwnProperty.call(clearData), key) {
          this.form[key] = clearData[key];
        }
      }
      // Clear currentItem (fields used inside product info)
      this.$store.dispatch(`${this.namespace}/clearProductInfo`, clearData);
      this.$refs.issuePurchasedItemDetailRef.localIssue = null;
    },

    optionFieldChange({ field, value }) {
      // Option dropdowns: Sync id and name
      if (field === "typeId") {
        this.optionFieldSync(value, field, "typeName", "itemOptions");
      } else if (field === "statusId") {
        this.optionFieldSync(value, field, "statusName", "itemOptions");
      } else if (field === "priorityId") {
        this.optionFieldSync(value, field, "priorityName", "itemOptions");
      } else if (field === "actionId") {
        this.optionFieldSync(value, field, "actionName", "itemOptions", "actionDescription");
        if (this.form.actionId === null) {
          this.form.returnedQty = 0;
          this.form.disposedQty = 0;
          this.form.listedQty = 0;
          this.form.otherQty = 0;
          this.form.otherQtyReason = null;
        }
        if (this.form.otherActionDescription && value !== 'Other Action') {
          this.form.otherActionDescription = "";
          this.$v.form.otherActionDescription.$reset();
        }
      } else if (field === "assigneeId") {
        this.optionFieldSync(
          value,
          field,
          "assigneeFamiliarName",
          "itemOptions"
        );
      } else if (field === "processorFacilityId") {
        this.optionFieldSync(
          value,
          field,
          "processorFacilityName",
          "itemOptions"
        );
      } else if (field === "clientFacilityId") {
        this.optionFieldSync(value, field, "clientFacilityName", "itemOptions");
      }
    },
    async optionFieldSync(value, valueField, textField, optionsField, descriptionField = "") {
      handleSyncOptionName({
        thisObj: this,
        value,
        valueField,
        textField,
        optionsField,
        descriptionField
      });
    },
    setAttachmentsChanged() {
      this.attachmentsChanged = true;
      this.$v.form.attachments.$touch();
    },

    async linkPurchasedItem() {
      const recordId = this.form.purchasedItemRecordId;
      if (!Number(recordId)) {
        this.purchasedItemRecordErrorMessage = 'Invalid record ID';
        this.$v.form.purchasedItemRecordId.$touch();
        return;
      }
      this.purchasedItemRecordErrorMessage = "";
      this.$v.form.purchasedItemRecordId.$reset();

      const payload = {
        purchasedItemRecordId: this.form.purchasedItemRecordId,
        clientId: this.currentItem?.clientId,
        tenantId: this.currentItem?.tenantId,
        allowNullResponse: true
      }
      try {
        // Try getting info for this purchased item id
        this.productInfo = await this.$store.dispatch(`${this.namespace}/fetchProductInfo`, payload);
        if (!this.productInfo) {
          // PurchasedItem id record not found
          this.purchasedItemRecordErrorMessage = `Purchased item record id ${this.form.purchasedItemRecordId} doesn't exist.`;
          this.$v.form.purchasedItemRecordId.$touch();
          this.$refs.purchRecordIdRef.$el.select()
          return;
        }
        this.purchasedItemRecordErrorMessage = "";
        this.$v.form.purchasedItemRecordId.$reset();
        this.$v.form.purchasedItemRecordId.$touch();
      } catch (error) {
        console.error(error)
        return;
      }
      // Load results into form
      delete this.productInfo?.id
      for (const key in this.productInfo) {
        if (Object.prototype.hasOwnProperty.call(this.productInfo), key) {
          this.form[key] = this.productInfo[key];
        }
      }
      await this.$nextTick();
    },
    async unreadFlagClear() {
      if (
        this.mode === "update" &&
        this.currentItem?.unread &&
        this.currentItem?.assigneeFamiliarName === this.user.familiarName
      ) {
        this.$store.dispatch(`${this.namespace}/currentItemFieldSet`, {
          key: "unread",
          value: 0,
        });
        this.$store.dispatch(
          `${this.namespace}/itemSaveUnreadFlag`,
          this.form?.id
        );
      }
    },
    async unreadFlagSet() {
      if (
        this.mode === "update" &&
        !this.currentItem?.unread &&
        this.currentItem?.assigneeFamiliarName !== this.user.familiarName
      ) {
        this.form.unread = 1;
      }
    },
    // *********************************************
    //          Item validate/close/cancel Methods
    // *********************************************
    async formCancel() {
      await this.unreadFlagClear();
      //if (!this.form.purchasedItemRecordId) this.clearProductInfo();
      await handleFormCancel(this);
    },

    async formSave() {
      await this.unreadFlagSet();
      await handleFormSave(this);
      this.$emit("save-issue-item", this.form);
      this.$emit("itemSaved");
    },

    async validateInteger(input) {
      // Remove any non-numeric characters
      const sanitizedInput = input.replace(/\D/g, "");
      await this.$nextTick();
      this.form.purchasedItemRecordId = sanitizedInput;
    },

    //   // Confirm changes
    //   async handleConfirmChanges(message) {
    //     // Confirm cancel
    //     const confirmOptions = {
    //       title: "Please Confirm",
    //       okVariant: "primary",
    //       cancelVariant: "light",
    //       okTitle: "Yes",
    //       cancelTitle: "No",
    //       footerClass: "p-2",
    //       bodyClass: "p-4 text-pre-line",
    //       hideHeaderClose: false,
    //       centered: true,
    //       html: true,
    //     };
    //     const reply = await this.$bvModal.msgBoxConfirm(message, confirmOptions);
    //     // Cancel the cancel
    //     if (reply === null) return null;
    //     else if (reply) {
    //       // Yes, save before leaving
    //       return true;
    //     } else {
    //       // No. Don't save before cancel
    //       return false;
    //     }
    //   }
    },
  };


</script>

<style scoped>

.b-card {
  position: relative;
}

.control-number-container {
  position: absolute;
  top: 15px;
  right: 15px;
}
.control-number-label {
  margin-right: 5px;
  font-size: .9rem;
  color: #666;
}
.control-number {
  font-weight: 600;
  color: #555;
}
.comments-scrollable-list {
  max-height: 450px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.comments-heading {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.9rem;
  color: #242323;
  text-transform: uppercase;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.history-scrollable-list {
  max-height: 250px;
  overflow-y: auto;
}

.product-description {
  font-size: 0.9rem;
  color: #777;
}

.description-block {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  line-height: 1.2rem;
}

.other-reason {
  margin-left: -10px;
}

.paper-clip {
  transform: rotate(-45deg);
}

.purchased-container {
  max-width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.small-label-text {
  font-size: .9rem;
  color: #777;
}

.purchased-qty-field {
  font-size: 1.0rem;
  color: #333;
}

.sub-heading {
  font-weight: 600;
  color: #555
}

.left-label {
  margin-left: 18px;
  font-size: .9rem;
  width: 150px;
}

.left-field {
  width: 230px;
  margin-right: 10px;
}

.issue-header-container {
  display: flex;
  align-items: center;
}
</style>
