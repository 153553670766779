<template>
  <div>
    <b-row>
      <b-col
        class="col-step"
        :class="{ 'col-step-inactive': !stepData.running && !stepData.done }"
      >
        <p class="m0">{{ stepData.name }}</p></b-col
      >
      <b-col class="col-spinner text-center">
        <!-- Spinner -->
        <b-spinner
          variant="primary"
          small
          v-if="stepData.running && !stepData.done"
        />
      </b-col>
      <b-col cols="1" v-if="expertMode">
        <b-button size="sm" @click="runStep"> Run </b-button>
      </b-col>
      <!-- Elapsed timer -->
      <b-col
        class="col-timer col"
        :class="{ 'col-step-inactive': !stepData.running }"
      >
        <p>{{ elapsedTime }}</p></b-col
      >
      <!-- Status -->
      <b-col class="col-status text-center">
        <p v-if="!stepData.done">{{ stepData.status }}</p>

        <div v-if="stepData.done" class="h2 check-div">
          <b-icon
            icon="check"
            variant="success"
            class="success-icon"
            :class="{ 'finish-check': step === 'finished' }"
          ></b-icon>
        </div>
      </b-col>
      <!-- Results -->
      <b-col>
        <div
          class="col-result"
          v-html="stepData.results"
          :class="{ 'finish-results': step === 'finished' }"
        ></div>
        <!-- <b-progress :max="stepData.progressMax" :value="stepData.progressValue">
        </b-progress> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dUtils from "../utils/date-utils";

export default {
  props: {
    namespace: { type: String, default: "" },
    step: {
      type: String,
      default: "",
    },
    expertMode: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  created() {
    this.$options.interval = setInterval(this.tick, 1000);
  },
  beforeDestroy() {
    clearInterval(this.$options.interval);
  },
  computed: {
    stepData() {
      return this.$store.getters[`${this.namespace}/${this.step}Step`];
    },
    elapsedTime() {
      return dUtils.formatSeconds(this.stepData.timer);
    },
  },
  methods: {
    runStep() {
      this.$emit("runStep", this.step);
    },
    tick() {
      if (
        !this.stepData.running ||
        this.stepData.done ||
        this.stepData.status === "COMPLETE"
      ) {
        return;
      }
      // Update step timer in store
      this.$store.dispatch(`${this.namespace}/${this.step}SetStep`, {
        timer: this.stepData.timer + 1,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.col {
  /* border: 1px solid red; */
}
.col-step {
  /* color: #ccc; */
  /* vertical-align: middle; */
}
.col-step-inactive {
  color: #aaa;
}
.col-spinner {
  max-width: 70px;
}
.col-timer {
  max-width: 90px;
}
.check-div {
  padding-top: 0;
}
.col-status {
  max-width: 120px;
  /* margin-top: auto; */
  /* margin-bottom: auto; */
  font-size: 0.8em;
  margin-top: 3px;
}
.finish-check {
  font-size: 2em;
}
.check-div {
  margin-top: -10px;
}
.col-result {
  white-space: pre-line;
  font-size: 0.8em;
  line-height: 17px;
  margin-top: auto;
  margin-bottom: auto;
}
.finish-results {
  font-size: 1em;
}
.success-icon {
  /* margin-top: auto; */
  margin-top: -8px;
}
</style>