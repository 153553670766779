<template>
  <div class="dboard">
    <!-- <DashboardQikPrep v-if="customDashboard && role === 'QikPrep Processing'" /> -->
    <!-- <DashboardAdministrator
      v-if="customDashboard && role === 'Administrator'"
    /> -->
    <!-- Default dashbaord -->
    <b-container v-if="!customDashboard" class="text-center dboard-cards px-3">
      <b-overlay :show="isLoading" :opacity="0.1">
        <h3 class="dboard-title">Dashboard</h3>
        <p>{{ user?.AuthRole?.roleName }}</p>
        <!-- Refresh dashboard button -->
        <b-button
          variant="light"
          class="ml-auto mb-1 mt-0"
          :title="refreshTooltip"
          @click.prevent="fetchOarKpis()"
        >
          <i class="fas fa-sync-alt refresh-icon-rs"></i>
        </b-button>
        <!-- <p>Auth expiry: {{ authExpiry }}</p> -->
        <b-container fluid>
          <!-- <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="6"> -->
          <b-row>
            <div fluid v-for="(kpi, index) in oarKpis" :key="index">
              <b-col>
                <BaseInfoCardSmall
                  :id="kpi.id"
                  :title="kpi.title"
                  :value="parseInt(kpi.value).toLocaleString()"
                  :uom="kpi.units"
                  :redGreenIndicator="kpi.showColor === 1 ? true : false"
                  :description="kpi.description"
                  @g3Clicked="showCardDetail(kpi.title)"
                />
              </b-col>
            </div>
          </b-row>
        </b-container>
      </b-overlay>
    </b-container>
    <!-- ReceiveSummary -->
    <b-modal id="show-receive-summary" centered hide-footer>
      <PurchasedItemReceiveSummary />
    </b-modal>
    <!-- ShippedSummary -->
    <b-modal id="show-shipped-summary" centered hide-footer>
      <OarShippedSummary />
    </b-modal>
    <!-- PendingSummary -->
    <b-modal id="show-pending-summary" centered hide-footer>
      <OarPendingSummary />
    </b-modal>
    <!-- 30 day receive activity summary -->
    <b-modal id="show-receive-30d-summary" centered hide-footer>
      <PurchasedItemReceive30dSummary />
    </b-modal>
    <!-- Pending Aged 30d Summary -->
    <b-modal id="show-pending-30d-summary" centered hide-footer>
      <OarPendingAged30dSummary />
    </b-modal>
    <!-- Comming soon placeholder -->
    <b-modal id="coming-soon" centered hide-footer>
      <b-container>
        <h4>Comming Soon...</h4>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
// import DashboardQikPrep from "./DashboardQikPrep.vue";
// import DashboardAdministrator from "./DashboardAdministrator.vue";
import OarPendingSummary from "../purchased-items/kpis/OarPendingSummary.vue";
import OarPendingAged30dSummary from "../purchased-items/kpis/OarPendingAged30dSummary.vue";
import PurchasedItemReceiveSummary from "../purchased-items/kpis/PurchasedItemReceiveSummary.vue";
import OarShippedSummary from "../purchased-items/kpis/OarShippedSummary.vue";
import PurchasedItemReceive30dSummary from "../purchased-items/kpis/PurchasedItemReceive30dSummary.vue";
import { handleRouterPushError } from "../../utils/component-utils.js";
// import { setSubNavText } from "../../utils/component-utils.js";
import config from "../../../config";

export default {
  components: {
    // DashboardQikPrep
    // DashboardAdministrator
    OarPendingSummary,
    OarPendingAged30dSummary,
    PurchasedItemReceiveSummary,
    OarShippedSummary,
    PurchasedItemReceive30dSummary
  },
  data() {
    return {
      title: "Dashboard",
      namespace: "Dashboard",
    };
  },
  async created() {
    document.title = "g3tools - Dashboard";
    this.setSubNav("");
    this.timer = setInterval(
      await this.fetchOarKpis(),
      config.autoListRefreshIntervalMin * 60 * 1000
    );
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async mounted() {
    // Get oar kpis
    // runs when the element is injected into the browser
    //this.setSubNav("Arbitrage");
    //this.role = this.user.role;
  },
  computed: {
    ...mapState("User", ["users"]),
    ...mapState("Session", ["auth"]),
    ...mapGetters("Session", [
      "auth",
      "user",
      "isAdmin",
      "isLoading",
      "isAuthenticated",
      "authExpiry",
      "customDashboard",
      "role"
    ]),
    ...mapGetters("Dashboard", ["oarKpis"]),
    refreshTooltip() {
      return `Refresh KPI's: \r${
        this.$store.getters[`${this.namespace}/lastRefreshed`]
      }\r\rNote: Some KPI's are refreshed by back-end processes running every 15 minutes`;
    },
    qpAgedPendingValue() {
      return this.oarKpis.find(x => x.title === "QP Aged Units")?.value;
    },
    tenantSelectedClientName() {
      return this.user?.tenantSelectedClient?.clientName || '';
    },
  },
  methods: {
    ...mapActions("Dashboard", ["fetchOarKpis"]),
    ...mapActions("Session", ["setSubNav", "setAccentColor"]),

    showCardDetail(cardTitle) {
      if (cardTitle === `Aged > ${config.pendingDays}d`) {
//        this.$bvModal.show("show-pending-30d-summary");
        this.$router.push({ name: "PurchasedItemReceiveXXd" }).catch(err => {
        handleRouterPushError(err);
      });
      } else if (cardTitle === "Total Pending") {
        this.$bvModal.show("show-pending-summary");
      } else if (cardTitle === "Purchased 30d") {
        this.$bvModal.show("show-pending-summary");
      } else if (cardTitle === "Received 30d") {
        this.$bvModal.show("show-receive-summary");
      } else if (cardTitle === "30d Flow Rate") {
        this.$bvModal.show("show-receive-summary");
      } else if (cardTitle === "Received Today") {
        this.$bvModal.show("show-receive-summary");
      } else if (cardTitle === `${this.user.processor.processorName} Issues`) {
        // Issues assigned to processor
        this.$router.push({ name: "IssuesAssignedToProcessor" }).catch(err => {
          handleRouterPushError(err);
        });
      } else if (cardTitle.includes(`${this.tenantSelectedClientName} Issues`)) {
        //setSubNavText(this.setSubNav, this.setAccentColor, "Issue");
        this.$router.push({ name: "IssuesAssignedToClient" }).catch(err => {
          handleRouterPushError(err);
        });
      } else if (cardTitle.includes("All Clients Issues")) {
        this.$router.push({ name: "IssuesAssignedToClient" }).catch(err => {
          handleRouterPushError(err);
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.dboard {
  background-color: rgb(252, 255, 255);
  height: calc(100vh - 53px);
}
.dboard-cards {
  min-width: 100%;
}
.dboard-title {
  padding-top: 10px;
  color: rgb(57, 149, 161);
  font-weight: 700;
}
.refresh-icon-rs {
  font-size: 1.1em;
  vertical-align: middle;
  color: rgb(139, 139, 139);
}
</style>