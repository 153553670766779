<template>
  <div :style="cssVars" class="label-value-group">
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :description="error ? '' : description"
      :label-cols="labelCols"
      :label-class="labelClass"
      :label-align="labelAlign"
      :label-size="size"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-textarea
        :aria-describedby="`${id}-live-feedback`"
        @change="updateValue(id, $event)"
        :class="{ 'readonly-mode': readonly, 'font-style-italic': italic, 'font-color-light': deemphasize}"
        :id="`${id}-input`"
        @input="touchValue(id, $event)"
        :max-rows="maxRows"
        :name="`${id}-input`"
        :no-resize="noResize"
        :plaintext="plaintext || readonly"
        :placeholder="placeholder"
        :readonly="readonly"
        :rows="rows"
        :size="size"
        :state="state"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px; margin-right: ${marginRightValue}px; background-color: ${backgroundColor}`"
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
      ></b-form-textarea>
      <b-form-invalid-feedback :id="`${id}-live-feedback`"
        :style="`margin-left: ${marginLeftValue}px;` ">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    backgroundColor: {type: String, default: "white"},
    compact: { type: Boolean, default: false },
    description: { type: String },
    deemphasize: {type: Boolean, deafult: false},
    error: { type: Boolean },
    errorMsg: { type: String },
    id: { type: String },
    italic: { type: Boolean, default: false },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "" },
    labelCols: { type: [Boolean, Number, String, null], default: null },
    labelClass: { type: String, default: "" },
    labelSize: { type: String, default: "" },
    marginLeft: { type: String, default: "" },
    marginRight:{ type: String, default: "" },
    marginLeftValue: { type: Number, String, default: null },
    marginRightValue: { type: Number, String, default: null },
    maxRows: { type: [String, Number], default: 99 },
    noResize: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    rows: { type: [String, Number], default: 1 },
    size: { type: [String, Number], default: "" },
    state: { type: [Boolean, null], default: null },
    textFieldWidth: { type: String, default: "" },
    value: [String, Number],

  },
  computed: {
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--margin-right": this.marginRight,
        "--margin-left": this.marginLeft,
      };
    }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    },
    touchValue(id, $event) {
      this.$emit("g3Touch", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}

.label-value-group {
  margin-right: var(--margin-right);
  margin-left: var(--margin-left);
}

.readonly-mode {
  background-color: #f8f9f8 !important;
  border: 0;
  margin-left: 2px;
  overflow-y: hidden !important;

}
.readonly-mode:focus-within {
  border: 1px solid #80bdff !important; 
  outline: 4px solid #badafb !important;
  border-radius: 4px;
}

.font-style-italic {
  font-style: italic;
}
.font-color-light {
  color: #666;
}

</style>
