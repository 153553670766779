import { render, staticRenderFns } from "./PurchasedItemHome.vue?vue&type=template&id=44a016e3&scoped=true&class=template"
import script from "./PurchasedItemHome.vue?vue&type=script&lang=js"
export * from "./PurchasedItemHome.vue?vue&type=script&lang=js"
import style0 from "./PurchasedItemHome.vue?vue&type=style&index=0&id=44a016e3&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a016e3",
  null
  
)

export default component.exports